import { defineStore } from 'pinia';
import UserService from '@/services/userService/UserService';

export interface UserState {
  id: number;
  title: string;
  last_login: string | null;
  is_superuser: boolean;
  is_staff: boolean;
  is_active: boolean;
  date_joined: string;
  name: string;
  last_name: string;
  email: string;
  phone_number: string;
  status: string;
  expiry_date: string | null;
  is_subscriber: boolean;
  subscription_start_date: string | null;
  subscription_end_date: string | null;
  is_admin: boolean;
  is_company_staff: boolean;
  is_client: boolean;
  is_member: boolean;
  is_user: boolean;
  avatar: string;
  smtp_accepted: boolean
  company: Company;
  subscription: {
    id: number;
    created_at: string;
    updated_at: string;
    platform_type: string;
    package_type: string;
    price: string;
    duration_in_days: number;
  };
  groups: any[]; // Define the structure of groups if needed
  user_permissions: any[]; // Define the structure of user permissions if needed
  languages: any[]; // Define the structure of languages if needed
  position: string;
  isFetchingUserInfo: boolean;
  currency: string;
  favorite: UserFavorite;
}

interface Company {
  id: number;
  created_at: string;
  updated_at: string;
  company_name: string;
  is_demo_company: boolean;
  company_type: 'ANA' | 'RS'; // Corrected type definition
  status: 'Active' | 'Inactive' | 'Pending'; // Assuming these are the only possible statuses
  email: string;
  phone_number: string;
  logo: string;
  description: string;
  country: string;
  city: string;
  services: any[];
  related_companies: RelatedCompany[];
  staff: any[]; // Define the structure of staff if needed
  project: any[]; // Define the structure of projects if needed
  announcements: any[]; // Define the structure of announcements if needed
}

interface RelatedCompany {
  id: number;
  created_at: string;
  updated_at: string;
  company_name: string;
  is_demo_company: boolean;
  company_type: 'ANA' | 'RS';
  status: 'Active' | 'Inactive' | 'Pending';
  email: string;
  phone_number: string;
  logo: string;
  description: string;
  related_companies: number[]; // IDs of related companies
  staff: number[]; // IDs of staff
  project: number[]; // IDs of projects
  announcements: number[]; // IDs of announcements
  image: number[]; // IDs of images
}

interface UserFavorite {
  announcements: number[];
  realestate: number[];
}

// Define actions
// interface UserActions {
//   fetchUser(): void;
//   patchUser(data: FormData): void;
//   updateUserById(userId: number, data: FormData): void;
// }

// Define the store
export const useUserStore = defineStore({
  id: 'userStore',
  state: (): UserState => ({
    id: 0,
    title: '',
    last_login: null,
    is_superuser: false,
    is_staff: false,
    is_active: false,
    smtp_accepted: false,
    date_joined: '',
    name: '',
    last_name: '',
    email: '',
    phone_number: '',
    status: 'Active',
    expiry_date: null,
    is_subscriber: false,
    subscription_start_date: null,
    subscription_end_date: null,
    is_admin: false,
    is_company_staff: false,
    is_client: false,
    is_member: false,
    is_user: false,
    avatar: '',
    company: {
      id: 0,
      created_at: '',
      updated_at: '',
      company_name: '',
      is_demo_company: false,
      company_type: 'ANA',
      status: 'Active',
      email: '',
      phone_number: '',
      logo: '',
      description: '',
      country: '',
      city: '',
      services: [],
      related_companies: [],
      staff: [],
      project: [],
      announcements: []
    },
    subscription: {
      id: 0,
      created_at: '',
      updated_at: '',
      platform_type: '',
      package_type: '',
      price: '',
      duration_in_days: 0
    },
    groups: [],
    user_permissions: [],
    languages: [],
    position: '',
    isFetchingUserInfo: false,
    currency: '',
    favorite: {
      announcements: [],
      realestate: []
    }
  }),
  actions: {
    async fetchUser(this: UserState) {
      this.isFetchingUserInfo = true;
      try {
        const userData = await UserService.getUserInfo();
        Object.assign(this, userData);
      } catch (error: any) {
        throw error;
      }
      this.isFetchingUserInfo = false;
    },
    async patchUser(this: UserState, data: FormData) {
      try {
        await UserService.updateUserInfo(data);
      } catch (error: any) {
        throw error;
      }
    },
    async updateUserById(this: UserState, userId: number, data: FormData) {
      try {
        const updatedUser = await UserService.updateUserById(userId, data);
        Object.assign(this, updatedUser);
      } catch (error: any) {
        throw error;
      }
    }
  }
});

