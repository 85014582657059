<script lang='ts'>
import { computed, defineComponent, provide, ref } from 'vue'
import Drawer from '../globalComponents/Drawer.vue'
import Breadcrumbs from '../globalComponents/Breadcrumbs.vue'
import { useTheme } from 'vuetify'

export default defineComponent({
  name: 'TheDashboardLayout',
  components: { Drawer, Breadcrumbs },
  setup() {
    const searchVisible = ref(false)
    const search = ref('')
    const triggerSearch = ref(false);
    const theme = useTheme()
    const searchEvent = () => {
      triggerSearch.value = true;
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        searchEvent();
      }
    };

    provide('searchVisible', searchVisible)
    provide('search', search)
    provide('searchEvent', searchEvent)
    provide('triggerSearch', triggerSearch);
    const todayDate = computed(() => {
      return new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    });

    return { searchVisible, search, searchEvent, handleKeydown, triggerSearch, todayDate, theme }
  }
})
</script>

<template>
  <v-layout>
    <drawer>
      <template v-slot:search>
        <div v-if='searchVisible' class='flex items-center gap-x-2 mx-4'>
          <v-text-field
            v-model='search'
            class='w-[150px] lg:w-[370px]'
            @keydown="handleKeydown"
            color='newBtn'
            base-color='newBtn'
            density='compact'
            hide-details
            variant='outlined'
            :style="{ color: theme.global.current.value.dark ? 'white' : 'black' }"
          />
          <div class='hidden lg:block'>
            <v-btn color='newBtn' @click='searchEvent' :disabled='!search' prepend-icon='mdi-magnify' variant='outlined'><span class='custom-disabled capitalize'>{{$t('global.textSearch')}}</span>
            </v-btn>
          </div>
          <div class='lg:hidden'>
            <v-btn color='newBtn' @click='searchEvent' :disabled='!search' icon='mdi-magnify' variant='outlined' class="custom-disabled rounded-lg" height='40px'></v-btn>
          </div>
        </div>
        <div v-else class='flex items-center gap-x-2 mx-4'>
            <v-icon :color="theme.global.current.value.dark ? 'white' : '#2979ff'" icon='mdi-calendar'></v-icon>
            <span :class="theme.global.current.value.dark ? 'text-theme-5' : 'text-theme-37'" class='text-lg font-semibold'>{{ todayDate }}</span>
        </div>
      </template>
    </drawer>
    <v-main class='dashboard-main'>
      <Breadcrumbs />
      <slot></slot>
    </v-main>
  </v-layout>
</template>

<style scoped>
.dashboard-main {
  margin-top: calc(30px + 1rem);
  margin-left: calc(36px + 10px);
  margin-right: calc(36px + 10px);
  width: -webkit-fill-available !important;
  width: fit-content;
}
::v-deep(.v-btn--disabled) {
  opacity: 1 !important;  /* Prevents graying out */
  pointer-events: auto;    /* Ensures hover effect works */
}
</style>
