// src/vuetify.ts
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Define the custom themes
const customDarkTheme = {
  dark: true,
  colors: {
    background: '#0A0C21',
    bgPrimary: 'rgba(255, 255, 255, 0.07)',
    surface: '#1b1d31',
    sheet: '#1b1d31',
    primary: '#00FFA3',
    secondary: '#03dac6',
    error: '#ff5722',
    navbar: '#343446',
    cards: '#1b1d31',
    drawer: '#14223D',
    customButtonColor: '#00ffa3',
    customTextColor: '#343446',
    offer: '#FFE081',
    project: '#05C3ED',
    conference: '#FFB1F6',
    launch: '#FFB688',
    projectCard: '#27293E0D',
    projectCardShadow: '#00FFA326',
    projectText: '#00FFA3',
    event: '#B3B3FF',
    typeEvent: '#d6d5ff',
    typeOffer: '#ffeebb',
    typeProject: '#78dff5',
    typeConference: '#ffd5fa',
    typeLaunch: '#ffd8bf',
    announcementDetailsEvent: '#36384c',
    dropdown: '#0A0C21',
    dropdownItem: '#00FFA3',
    'surface-variant': '#0A0C21',
    'on-surface-variant': '#ffffff',
    cardBackground: 'rgba(255, 255, 255, 0.07)', // semi-transparent white
    cardTextColor: '#ffffff', // white text color
    cardBorder: '#00ffa326', // Dark theme card border
    cardShadow: '#00ffa326', // Dark theme card shadow
    marketplaceCard: '#1b1c2b',
    requestBtn: '#B3B3FF',
    offerBtn: '#05C3ED',
    marketplaceDetailBtn: '#2c3fff',
    networkButton: '#b61efa',
    networkPrimary: '#00FFA3',
    newRealEstateComponent: '#1b1d31',
    realEstateCardType: '#00FFA3',
    pendingBtn:"#BABABA",
    realEstatePendingType:"#FFE9A8",
    realEstateRequestType:"#CDCDFF",
    networkingRequestBtn:"#8383EB4D",
    networkingPendingBtn:"#FFE0814D",
    networkingCircleTag:"#E09AFF",
    customColor:"#07EF81",
    switchBg: "#e7e7e7",
    activeLink: "rgba(4, 102, 200, 0.4)",
    newBtn: "#2979FF",
    blackAndWhite: "#000000",
    filterDialog: "#14223D",
    filtersAccordion: "rgba(251, 251, 251, 0.08)",
    announcementCardIcon: "#FFFFFF",
    announcementCard:"#0D182C"
  }
}

const customLightTheme = {
  dark: false,
  colors: {
    background: '#E8E8E8',
    bgPrimary: 'rgba(255, 255, 255)',
    surface: '#27293e',
    sheet: '#ffffff',
    primary: '#27293E',
    secondary: '#00ccff',
    error: '#ff5722',
    navbar: '#ffffff',
    cards: '#fafafa',
    drawer: '#F1F1F1',
    customButtonColor: '#27293E',
    customTextColor: '#ffffff',
    offer: '#FFE081',
    project: '#05C3ED',
    conference: '#FFB1F6',
    launch: '#FFB688',
    projectCard: '#F4F4F4',
    projectCardShadow: '#FAFAFA',
    projectText: '#8811FF',
    event: '#B3B3FF',
    typeEvent: '#d6d5ff',
    typeOffer: '#ffeebb',
    typeProject: '#78dff5',
    typeConference: '#ffd5fa',
    typeLaunch: '#ffd8bf',
    announcementDetailsEvent: '#e2fae9',
    dropdown: '#ffffff',
    dropdownItem: '#ffffff',
    cardBackground: '#ffffff', // solid white
    cardTextColor: '#000000', // black text color
    cardBorder: '#e0e0e0', // Light theme card border
    cardShadow: '#e0e0e0', // Light theme card shadow
    marketplaceCard: '#ffffff',
    requestBtn: '#B3B3FF',
    offerBtn: '#05C3ED',
    marketplaceDetailBtn: '#6371ff',
    networkButton: '#B3B3FF',
    networkPrimary: '#6DE98A',
    newRealEstateComponent: '#fcfcfc',
    realEstateCardType: '#FFFED5',
    pendingBtn:"#BABABA",
    realEstatePendingType:"#FFE9A8",
    realEstateRequestType:"#CDCDFF",
    networkingRequestBtn:"#8383EB4D",
    networkingPendingBtn:"#F4C73F4D",
    networkingCircleTag:"#E09AFF",
    customColor:"#07EF81",
    switchBg: "#808080",
    activeLink: "rgba(4, 102, 200, 0.4)",
    newBtn: "#2979FF",
    blackAndWhite: "#ffffff",
    filterDialog: "#fafafa",
    filtersAccordion: "rgba(251, 251, 251, 0.08)",
    announcementCardIcon: "#000000",
    announcementCard:"#fafafa"
  }
}

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'dark',
    themes: {
      dark: customDarkTheme,
      light: customLightTheme
    }
  }
})

export default vuetify

