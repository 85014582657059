<script lang="ts" setup>
import { ref, computed, onMounted, nextTick, onUnmounted, watch } from 'vue'
// import SvgLoader from './SvgLoader.vue'
import { useTheme } from 'vuetify'
import avatar1 from '../assets/images/announcement/avatars/avatar1.jpg'
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@/store/authentication/AuthStore.ts'
import { useUserStore } from '@/store/user/UserStore.ts'
import { useI18n } from 'vue-i18n'
// import logo from '../assets/images/lastfloors/lastfloorsLogo.png'
import { useNotificationStore } from '@/store/notification/NotificationStore.ts'
import placeholder from "../../src/assets/images/placeHolders/logo-placeholder.jpg";
// import darkThemeImage from "@/assets/img.png"
// import lightThemeImage from "@/assets/img_2.png"
import darkThemeWinterImage from "@/assets/images/lastfloors/KarLF_logo1.png"
import lightThemeWinterImage from "@/assets/images/lastfloors/LFlogo_kar.png"
// import winterMiniLogo from "@/assets/images/lastfloors/karpin5.png"
import Cookies from 'js-cookie'
import { useChatStore } from '@/store/chat/ChatStore.ts'
import { storeToRefs } from 'pinia'
import smallLogo from "../assets/images/lastfloors/img_1.png"
import smallBlueLogo from "../assets/images/lastfloors/img_7.png"
import greenLastfloors from "../assets/images/lastfloors/img_4.png"
import lastfloorsDark from "@/assets/images/lastfloors/drawer-lastfloors-logo-dark.png"
import lastfloorsLight from "@/assets/images/lastfloors/drawer-lastfloors-logo-light.png"

interface CustomListItem {
  icon: string
  text: string
  path: string
  notifications: boolean
}
    const { t, locale } = useI18n({ useScope: 'global' })
    const router = useRouter()
    const route = useRoute()
    const authStore = useAuthStore()
    const userStore = useUserStore()
    const chatStore = useChatStore()
    const { chatRooms } = storeToRefs(chatStore)
    const dropdownExpanded = ref(false);
    const userData = computed(() => userStore.$state)
    const isLogoutModalOpen = ref(false)
    const isMobile = ref(window.innerWidth < 767)
    const notifications = ref<number>(0)
    const handleResize = () => {
      const newIsMobile = window.innerWidth < 767
      if (isMobile.value !== newIsMobile) {
        isMobile.value = newIsMobile
        nextTick(() => {
          // Additional UI adjustments can be made here if necessary
        })
      }
    }

    onMounted(() => {
      userStore.fetchUser()
      window.addEventListener('resize', handleResize)
    })

    onMounted(async () => {
      await notificationStore.fetchUnreadCounter()
    })

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })

    const goToProfile = () => {
      router.push('/user-profile')
    }

    const notificationStore = useNotificationStore()
    const totalNotifications = computed(
      () =>
        notificationStore.unreadCounter +
        notificationStore.requestCounter +
        notificationStore.invitationCounter
    )

    const goToNotifications = () => {
      router.push('/notifications')
    }

    const logout = () => {
      isLogoutModalOpen.value = false
      authStore.logout()
      nextTick(() => {
        router.push('/login')
      })
    }

    const routeMapping = {
      profile: "/user-profile",
      subscription: "/subscription-plan",
      company: "/company-profile",
    };

    const items = [
      {
        text: 'sideNavBar.buttonAnnouncementWall',
        name: 'mdi-bullhorn-outline',
        path: '/announcements/wall',
        child: false,
        notifications: false
      },
      { text: 'sideNavBar.buttonRsMangement', name: 'mdi-city-variant-outline', path: '/my-real-estates', child: false, notifications: false },
      {
        text: 'sideNavBar.networking',
        name: 'mdi-account-group-outline',
        path: '/networking',
        child: false,
        notifications: false
      },
      // {
      //   text: 'sideNavBar.buttonMarketplace',
      //   name: 'mdi-cards-outline',
      //   path: '/marketplace',
      //   child: false,
      //   notifications: false
      // },
      { text: 'sideNavBar.buttonOverview', name: 'mdi-view-dashboard-outline', path: '/overview', child: false, notifications: false },
      {
        name: 'mdi-chat-processing-outline',
        text: 'chat.chats',
        path: '/chat',
        child: false,
        notifications: true
      },
    ]

    const customDrawerList: CustomListItem[] = [
      { icon: 'mdi-account-outline', text: 'topNavBar.buttonProfile', path: '/user-profile',   notifications: false },
      {
        icon: 'mdi-briefcase-outline',
        text: 'topNavBar.buttonCompanyProfile',
        path: '/company-profile',
        notifications: false
      },
      {
        icon: 'mdi-bullhorn-outline',
        text: 'sideNavBar.buttonAnnouncementWall',
        path: '/announcements/wall',
        notifications: false
      },
      { icon: 'mdi-city-variant-outline', text: 'sideNavBar.allPortfolio', path: '/my-real-estates',   notifications: false },
      {
        icon: 'mdi-account-group-outline',
        text: 'sideNavBar.networking',
        path: '/networking',
        notifications: false
      },
      // { icon: 'mdi-cards-outline', text: 'sideNavBar.buttonMarketplace', path: '/marketplace',   notifications: false },
      { icon: 'mdi-view-dashboard-outline', text: 'sideNavBar.buttonOverview', path: '/overview',   notifications: false },
      {
        icon: 'mdi-chat-processing-outline',
        text: 'chat.chats',
        path: '/chat',
        notifications: true
      },
      { icon: 'mdi-logout', text: 'sideNavBar.logOut', path: '/login',   notifications: false }
    ]

    const customList: CustomListItem[] = [
      { icon: 'mdi-crown-outline', text: 'topNavBar.buttonSubplan', path: '/subscription-plan',   notifications: false },
      { icon: 'mdi-account-outline', text: 'topNavBar.buttonProfile', path: '/user-profile',   notifications: false },
      {
        icon: 'mdi-briefcase-outline',
        text: 'topNavBar.buttonCompanyProfile',
        path: '/company-profile',
        notifications: false
      }
    ]

const dropdownItems = ref([
  { key: "profile", label: 'topNavBar.buttonProfile', icon: "mdi-account" },
  {
    key: "subscription",
    label: 'topNavBar.buttonSubplan',
    icon: "mdi-crown",
  },
  {
    key: "company",
    label: 'topNavBar.buttonCompanyProfile',
    icon: "mdi-domain",
  },
  { key: "logout", label: 'topNavBar.buttonLogout', icon: "mdi-logout" },
]);

    const theme = useTheme()

    const drawer = ref(true)
    const rail = ref(true)

    const model = computed({
      get: () => drawer.value,
      set: (value) => {
        drawer.value = value
      }
    })

    const topNav = ref({
      avatar: avatar1
    })

    const toggleDropdown = (item: any) => {
      item.dropdown = !item.dropdown
    }

    const toggleTheme = (key: any) => {
      // const newTheme = theme.global.current.value.dark ? 'light' : 'dark';
      theme.global.name.value = key;
      localStorage.setItem('theme', key);
    }
    const initializeTheme = () => {
      const savedTheme = localStorage.getItem('theme')
      if (savedTheme) {
        theme.global.name.value = savedTheme
      } else {
        const defaultTheme = 'dark'
        theme.global.name.value = defaultTheme
        localStorage.setItem('theme', defaultTheme)
      }
    }

    initializeTheme()

    const toggleLogoutModal = () => {
      isLogoutModalOpen.value = !isLogoutModalOpen.value
    }

    const themeImageUrl = computed(() => {
      // return theme.global.current.value.dark === true ? darkThemeImage : lightThemeImage
      return theme.global.current.value.dark === true ? darkThemeWinterImage : lightThemeWinterImage
    })

    watch(chatRooms, (newChatRooms) => {
      notifications.value = newChatRooms.reduce((total, item) => {
        return total + (item.unread_count || 0)
      }, 0)
    })

    const getChatRoomLists = () => {
      const token = Cookies.get('access_token')
      chatStore.getChatRoomsAction(token);
    }

const profileToggle = () => {
  if (!rail.value) {
    dropdownExpanded.value = !dropdownExpanded.value;
  } else {
    rail.value = false;
  }
}
const toggleProfileDropdown = () => {
  rail.value = !rail.value
  setTimeout(() => {
    dropdownExpanded.value = true
  }, 1000)
}

const handleItemClick = (key) => {
  if (key === "logout") {
    isLogoutModalOpen.value = true;
    return;
  }

  const routePath = routeMapping[key];
  if (routePath) {
    router.push(routePath);
  }
};

const activeStyles = computed(() => ({
  backgroundColor: theme.global.current.value.dark
    ? "rgba(41, 121, 255, 0.6)"
    : "rgba(41, 121, 255)",
  color: "#07e781",
  opacity: "1",
  borderRadius: "8px !important",
}));
</script>

<template>
  <div>
    <v-navigation-drawer color='drawer' v-if="!isMobile" v-model="model" :class="{ 'rail-mode': rail }" :rail="rail" :width="302"
                          app class="primary drawer" left :rail-width='141' temporary persistent permanent>
      <div class='flex items-center mt-10' :class="rail ? 'justify-center mx-4' : 'justify-between mx-8'">
        <div v-if='!rail'>
          <v-img v-if="theme.global.name.value === 'dark'" :src='lastfloorsLight' width="145" height="30"></v-img>
          <v-img v-else :src='lastfloorsDark' width="145" height="30"></v-img>
        </div>
        <div v-if='!rail' class='w-7 h-7 rounded-lg' style='background-color: #2979ff !important' @click='rail= false'>
          <v-icon icon="mdi-chevron-left" size='large' @click.stop="rail = !rail" color='blackAndWhite'></v-icon>
        </div>
        <div v-if='rail' @click.stop="rail = !rail" class='cursor-pointer'>
          <v-img v-if="theme.global.name.value === 'dark'" :src='smallLogo' width="60" height="30"></v-img>
          <v-img v-else :src='smallBlueLogo' width="60" height="30"></v-img>
        </div>
      </div>
      <div class='mx-4 mt-4 bg-theme-38 h-1'></div>
      <div
        class="flex items-start flex-col gap-y-3 justify-center mt-4 cursor-pointer mb-6 mx-4"
        v-if='rail'
      >
        <div class='relative'>
          <v-avatar size="75" @click="toggleProfileDropdown">
            <v-img :src="userData.avatar" alt="Avatar" />
          </v-avatar>
          <div v-if='totalNotifications > 0' class='absolute -top-1 -right-1 cursor-pointer' @click="goToNotifications">
            <div class='relative'>
              <v-icon size='x-large' icon='mdi-bell' color='#07EF81'></v-icon>
              <span class='absolute inset-0 flex justify-center items-center text-sm font-bold text-black'>{{ totalNotifications }}</span>
            </div>
          </div>
          <router-link :to="{ name: 'CompanyProfile' }">
            <div class='flex gap-x-1 items-center justify-center absolute -bottom-4 -right-8'>
              <v-avatar size='54' :image='userData.company.logo' class='company-avatar'>
              </v-avatar>
            </div>
          </router-link>
        </div>
      </div>

      <!-- Dropdown Section -->
      <div v-if="!rail" class="mt-4 mx-4">
        <div class="flex justify-between items-center">
          <div class="flex gap-x-2 mx-4 w-100">
            <div class='relative'>
              <v-avatar size="65">
                <v-img :src="userData.avatar" alt="Avatar" />
              </v-avatar>
              <div v-if='totalNotifications > 0' class='absolute -top-1 -right-1 cursor-pointer' @click="goToNotifications">
                <div class='relative'>
                  <v-icon size='x-large' icon='mdi-bell' color='#07EF81'></v-icon>
                  <span class='absolute inset-0 flex justify-center items-center text-sm font-bold text-black'>{{ totalNotifications }}</span>
                </div>
              </div>
            </div>
            <div class="flex flex-col gap-y-1 w-100">
              <span class="text-base">{{$t('topNavBar.textGoodDay')}},</span>
              <div class='flex justify-between items-center w-100'>
                <span v-if="!isMobile" class="text-2xl font-bold">
                {{ userData.name || "Name" }}
              </span>
                <div @click="profileToggle" class="cursor-pointer">
                  <v-icon
                    :icon="dropdownExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                    size="x-large"
                  ></v-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <transition name="dropdown-animation">
        <v-list v-if="dropdownExpanded" class="dropdown-list mt-1" lines="one" density="compact" color='#fbfbfb'>
          <v-list-item
            v-for="(item, index) in dropdownItems"
            :key="index"
            color='#fbfbfb'
            class="mt-1"
            :style="route.path === routeMapping[item.key] ? activeStyles : {}"
            @click="handleItemClick(item.key)"
          >
            <div class='flex gap-x-1 items-center'>
              <v-icon :icon="item.icon" class="text-sm"></v-icon>
              <span class='text-sm'> {{ $t(item.label) }}</span>
            </div>
          </v-list-item>
        </v-list>
        </transition>
        <div class='mx-6 my-3 rounded-lg' style='border: 1px solid #878787'></div>
        <router-link :to="{ name: 'CompanyProfile' }">
        <div class='flex gap-x-1 items-center justify-center mt-2'>
          <v-avatar size='40' :image='userData.company.logo || placeholder' class='company-avatar'>
          </v-avatar>
          <span v-if="!isMobile" class="ml-4 capitalize whitespace-wrap font-[500]">{{ userData.company.company_name }}</span>
        </div>
        </router-link>
      </div>
      <div v-if="theme.global.name.value === 'dark'" class='my-3 mx-4' style='border: 1px solid #878787'></div>
      <div v-if="theme.global.name.value === 'light'" class='my-3 mx-4' style='border: 1px solid #5e5e5e'></div>
      <v-list active-class="selected_item" class="mt-2" density="compact">
<!--        <v-list-subheader class="px-0 flex justify-center items-center" style="padding-inline-start: 0 !important">-->
<!--          <div class='px-2'>-->
<!--            <div v-if="rail">-->
<!--&lt;!&ndash;              <SvgLoader name="smallLogo" style="stroke: none" />&ndash;&gt;-->
<!--&lt;!&ndash;              <img :src='winterMiniLogo' alt='lastfloors-logo'/>&ndash;&gt;-->
<!--            </div>-->
<!--            <div v-else class="flex justify-between items-center mt-3">-->
<!--              <div>-->
<!--&lt;!&ndash;                <SvgLoader :name="'drawerLogo'" style="stroke: none" />&ndash;&gt;-->
<!--                <img :src="themeImageUrl" alt='lastfloors'>-->
<!--              </div>-->
<!--              <v-icon style="margin-left: 70px" color="primary" icon="mdi-radiobox-marked"-->
<!--                      @click.stop="rail = !rail"></v-icon>-->
<!--            </div>-->
<!--          </div>-->
<!--        </v-list-subheader>-->

        <div :class="!rail ? 'mx-2' : 'mx-6'">
          <template v-for="(item, i) in items">
            <!-- Render items without children -->
            <v-list-item
              variant="flat"
              base-color='drawer'
              color='activeLink'
              rounded='lg'
              :class="[!item.child ? 'default-opacity' : 'custom-opacity', rail ? 'flex justify-center w-auto' : 'mx-2']"
              active-class='parent-items'
              v-if="!item.child"
              :key="'item-' + i"
              :to="item.path"
              class="mt-3"
            >
              <template #prepend v-if='!rail'>
                <v-icon :icon='item.name' size='large'></v-icon>
              </template>
              <template #title v-if='!rail'>
                <div>
                  {{ $t(item.text) }}
                </div>
              </template>
              <div v-if='rail' class='flex justify-center items-center'>
                <v-icon :icon='item.name'></v-icon>
              </div>
              <template #append>
                <div v-if='item.notifications && notifications > 0 && !rail' class='bg-red-600 w-5 h-5 rounded-full flex justify-center items-center text-white'>
                  {{ notifications }}
                </div>
              </template>
            </v-list-item>

            <!-- Render items with children -->
            <v-list-group v-else :key="'group-' + i" :value="item.text">
              <template v-slot:activator="{ props, isOpen }">
                <v-list-item
                  variant="flat"
                  base-color='background'
                  color='primary'
                  rounded='lg'
                  :class="['default-opacity']"
                  active-class='parent-items'
                  :append-icon="rail ? '' : ''"
                  :to="item.path"
                  class="mt-3 mx-2"
                  v-bind="props"
                >
                  <template #prepend v-if='rail'>
                    <v-icon :icon='item.name'></v-icon>
                  </template>
                  <div class="flex justify-between gap-x-2">
                    <div class="flex gap-x-1 items-center">
                      <v-list-item-title variant="flat" base-color='background' color='primary' rounded='xl' active-color='primary' v-if="!rail">{{ $t(item.text) }}</v-list-item-title>
                    </div>
                    <v-icon v-if="!rail">
                      {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                  </div>
                </v-list-item>
              </template>

              <!-- Render child items -->
              <div v-if="!rail" class="mt-3">
                <v-list-item
                  rounded='lg'
                  color='primary'
                  :prepend-icon='child.name'
                  :class="['custom-opacity']"
                  active-class='child-items'
                  v-for="(child, j) in item.children"
                  :key="'child-' + j"
                  :to="child.path"
                  class='mx-4'
                >
                  <template #title>
                    <span v-if='!rail'>{{ $t(child.text) }}</span>
                  </template>
                </v-list-item>
              </div>
            </v-list-group>
          </template>


        </div>
        <div v-if='!rail'>
          <div v-if="theme.global.name.value === 'dark'" class='my-4 mx-4' style='border: 1px solid white'></div>
          <div v-if="theme.global.name.value === 'light'" class='my-4 mx-4' style='border: 1px solid #5e5e5e'></div>
        </div>
        <div v-if='!rail' class='flex justify-end mx-4 gap-x-2'>
          <v-icon class='rotate-[-40deg]' @click="toggleTheme('dark')" :color="theme.name.value === 'dark' ? '#2979ff' : '#b2b2b2'" icon='mdi-moon-waning-crescent'></v-icon>
          <v-icon @click="toggleTheme('light')" :color="theme.name.value === 'light' ? '#2979ff' : '#b2b2b2'" icon='mdi-white-balance-sunny'></v-icon>
        </div>
      </v-list>
<!--      <template v-slot:append>-->
<!--        <div class="pb-4 flex items-start justify-start">-->
<!--          <v-btn prepend-icon='mdi-logout' variant="text" v-if="!rail"-->
<!--                 @click="toggleLogoutModal" color='primary' class='ml-2'>-->
<!--            <span class='capitalize'>{{ $t('sideNavBar.logOut') }}</span>-->
<!--          </v-btn>-->
<!--          <v-icon color='primary' class="ml-5" v-if="rail" @click="toggleLogoutModal" block icon='mdi-logout'></v-icon>-->
<!--        </div>-->
<!--      </template>-->
    </v-navigation-drawer>
    <v-app-bar clipped-left app rounded flat class="relative my-5 mx-5 md:mx-11"  :style="{ background: theme.global.current.value.dark
      ? 'linear-gradient(90deg, #1a1c3a 0%, #0a0c21 100%)'
      : 'linear-gradient(90deg, #FAFAFA 0%, #E8E8E8 100%)'
  }" height="70" :class="{ 'expanded-title': rail || isMobile, 'rail-active-toolbar': !rail }">
      <div class='flex justify-between w-100'>
<!--        <router-link to='/'>-->
<!--          <div class='flex justify-start mx-4'>-->
<!--            <v-img :src='greenLastfloors' width='223' height='46'></v-img>-->
<!--          </div>-->
<!--        </router-link>-->
        <slot name="search"></slot>
        <v-menu v-if="isMobile" offset-y>
          <template v-slot:activator="{ props }">
            <v-btn icon="mdi mdi-menu" v-bind="props"></v-btn>
          </template>
          <v-card color='background'>
            <v-list-item
              v-for="(item, index) in customDrawerList"
              :key="index"
              :to="item.path"
              color='primary'
              class='custom-opacity'
              v-show="index < 3 || isMobile"
            >
              <v-list-item-content class='flex justify-between'>
                <v-list-item-title>
                  <v-icon left>{{ item.icon }}</v-icon>
                  {{ $t(item.text) }}
                </v-list-item-title>
                <div v-if='item.notifications' class='text-red-600'>
                  {{ notifications }}
                </div>
              </v-list-item-content>
            </v-list-item>
          <div v-if='!rail' class='flex m-4 gap-x-2'>
           <v-icon class='rotate-[-40deg]' @click="toggleTheme('dark')" :color="theme.name.value === 'dark' ? '#2979ff' : '#b2b2b2'" icon='mdi-moon-waning-crescent'></v-icon>
           <v-icon @click="toggleTheme('light')" :color="theme.name.value === 'light' ? '#2979ff' : '#b2b2b2'" icon='mdi-white-balance-sunny'></v-icon>
         </div>
          </v-card>          
        </v-menu>
      </div>
      <div class="absolute bottom-0 left-0 w-full h-[3px] bg-gradient-to-r from-green-400 to-transparent"></div>
    </v-app-bar>
    <template>
      <v-dialog v-model="isLogoutModalOpen" max-width="400">
        <v-card color='background'>
          <v-card-title>{{ $t('sideNavBar.logoutModalTitle') }}</v-card-title>
          <v-card-text>{{ $t('sideNavBar.logoutModalText') }}</v-card-text>
          <v-card-actions>
            <v-btn color="primary" variant='outlined' @click="logout">{{ $t('sideNavBar.logoutModalYes') }}</v-btn>
            <v-btn @click="toggleLogoutModal">{{ $t('sideNavBar.logoutModalNo') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>
<style lang="scss" scoped>
.dropdown-list {
  ::v-deep(.v-list-item) {
    height: 20px !important;
  }
  ::v-deep(.v-list-item:hover) {
    border-radius: 8px !important;
    color: #07e781;
    opacity: 1 !important;
  }
}
.v-navigation-drawer {
  border-top-right-radius: 60px;
}
::v-deep(.v-list-item__prepend) {
  width: 35px !important;
}
::v-deep(.v-list-item--active > .v-list-item__overlay, .v-list-item[aria-haspopup=menu][aria-expanded=true] > .v-list-item__overlay) {
  opacity: 0 !important;
}

::v-deep(.custom-opacity.v-list-item--active > .v-list-item__overlay) {
  opacity: calc(var(--v-activated-opacity) * var(--v-theme-overlay-multiplier)) !important;
}

::v-deep(.default-opacity .v-list-item__overlay) {
  opacity: var(--v-overlay-opacity, 0) !important;
}
::v-deep(.v-list-item-title) {
    color: var(--v-theme-primary) !important;
    font-size: 14px;
}

.custom-margin {
  margin-top: 75px;
}

//.rail-mode {
//  border-top-right-radius: 60px;
//}


.rail-mode .v-list-subheader .flex {
  align-items: center;
  justify-content: space-between;
}

.v-list-subheader svg {
  width: 100%;
  height: auto;
  max-height: 100%;
}


.v-navigation-drawer--active {
  position: fixed !important;
}

.v-navigation-drawer--left {
  border-right-width: 0 !important;
}

.divider {
  height: 20px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
//@media (prefers-color-scheme: light) {
//  .drawer {
//    box-shadow: 4px 0px 8px 0px #6C6C6C33;
//  }
//  //.v-toolbar {
//  //  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(0, 0, 0, 0.1) !important;
//  //}
//}

.name-label {
  color: var(--v-theme-primary-base);
}

.text-xs {
  font-size: 0.75rem;
}
@media only screen and (max-width: 768px) {
  .v-toolbar {
    display: flex !important;
    width: calc(100% - 90px) !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 2rem !important;
  }
}
@media only screen and (min-width: 768px) {
  .v-toolbar {
    margin-left: calc(36px + 10px) !important;
    width: -webkit-fill-available !important;
    width: fit-content;
  }
  //.rail-active-toolbar {
  //  width: calc(100% - 85px) !important;
  //}
}
@keyframes shake {
  0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(10deg);
  }
}

.shake-animation {
  animation: shake 0.5s ease-in-out infinite;
}
.dark-menu {
  background-color: var(--v-theme-background);
  color: var(--v-theme-on-background);
}

.light-menu {
  background-color: var(--v-theme-background);
  color: var(--v-theme-on-background);
}

.v-list-item-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.v-list-item {
  color: var(--v-theme-primary);
}
.dropdown-animation-enter-active,
.dropdown-animation-leave-active {
  transition: max-height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.dropdown-animation-enter-from {
  max-height: 0;
  opacity: 0;
}

.dropdown-animation-enter-to {
  max-height: 200px; /* Adjust based on your dropdown's content */
  opacity: 1;
}

.dropdown-animation-leave-from {
  max-height: 200px; /* Same as enter-to */
  opacity: 1;
}

.dropdown-animation-leave-to {
  max-height: 0;
  opacity: 0;
}
.company-avatar {
  ::v-deep(.v-img) {
    .v-img__img {
      object-fit: cover !important;
    }
  }
}
.parent-items {
  .v-icon {
    color: #07EF81 !important;
  }
  .v-list-item-title {
    div  {
      color: #07EF81 !important;
    }
  }
}
.navbar {
  border-bottom: 4px solid !important;

  border-image-source: linear-gradient(90deg, #07EF81 0%, #0A0C21 100%) !important;
}
</style>
